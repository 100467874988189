<template>
  <div class="app-container">
    <search-header :timeSelect="timeSelect" :query="query" @search="search" />
    <CommonTable
      :selection="false"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      @sort-change="sortChange"
    >
      <template #tradeTime="{ scoped: row }"> {{ parseTime(row[row.prop]) }} </template>
      <template #tradeType="{ scoped: row }">{{ tradeType(row.tradeType) }}</template>
      <!-- <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="{ row }">
          <color-text-btn @click="handleDetail(row)">详情</color-text-btn>
        </template>
      </el-table-column> -->
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import searchHeader from './module/searchHeader.vue'
import initDataMixin from '@/mixins/initData'
import { parseTime } from '@/utils'
import { balanceCols as cols } from './module/cols'
import { TRADE_TYPE_LABELS } from '@/utils/constant/paymentConst'

export default {
  components: { searchHeader },
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      sup_this: this,
      url: '/externaladmin/financeService/tradeFlowStatement/list',
      query: {
        creditMonthBillId: this.$route.query.id,
        isReturnOrder: 1
      },
      timeSelect: {
        time: null
      }
    }
  },
  computed: {
    tradeType() {
      return (type) => {
        return TRADE_TYPE_LABELS[type]
      }
    }
  },
  methods: {
    search() {
      if (typeof this.timeSelect.time == 'object') {
        if (this.timeSelect.time) {
          this.query.tradeStartTime = this.timeSelect.time[0] + ' 00:00:00'
          this.query.tradeEndTime = this.timeSelect.time[1] + ' 23:59:59'
        } else {
          this.query.tradeStartTime = ''
          this.query.tradeEndTime = ''
        }
      }
      this.searchChange()
    },
    // 结账日期设置
    dateSet() {
      this.visible = true
    },
    parseTime,
    sortChange({ prop, order }) {
      if (prop == 'levelName') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'member_level',
              asc: state
            }
          ]
        }
        this.init()
      } else if (prop == 'accountAmount') {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'account_amount',
              asc: state
            }
          ]
        }
        this.init()
      } else if ((prop = 'tradeTime')) {
        const state = order == 'ascending' ? true : false
        this.mergeData = {
          orderItems: [
            {
              column: 'trade_time',
              asc: state
            }
          ]
        }
        this.init()
      }
    },
    handleDetail(data) {
      const { id } = data
      this.$router.push({ path: '/financialManagement/historyDetail', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
</style>
